import { ISignUp } from "../../interfaces/auth/ISignUp";
import { IUserData } from "../../interfaces/user/IUserData";
import { fetcher } from "../../lib/fetcher";
import * as Sentry from "@sentry/nextjs";

async function signup(params: ISignUp): Promise<IUserData> {
  return (await fetcher("auth/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  }).catch((e: Error) => {
    Sentry.captureException({
      category: "signup",
      message: "error on signup",
      data: e,
    });

    throw e;
  })) as IUserData;
}

async function list(user: IUserData): Promise<IUserData> {
  if (user.id && user.token) {
    return (await fetcher(`users/${user?.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    }).catch((e: Error) => {
      console.error(e);
      Sentry.captureException({
        category: "user",
        message: "error getting user data on user api",
        data: e,
      });
    })) as IUserData;
  } else {
    return;
  }
}

async function update(user: IUserData, data: unknown) {
  if (user.id) {
    return (await fetcher(`users/${user?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(data),
    }).catch((e: Error) => {
      console.error(e);
      Sentry.captureException({
        category: "user",
        message: "error updating user on user api",
        data: e,
      });
    })) as IUserData;
  } else {
    return;
  }
}

async function resetPassword(params: { email: string }) {
  return (await fetcher(
    "auth/forgot",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    },
    false
  ).catch((e: Error) => {
    console.error(e);
    Sentry.captureException({
      category: "user",
      message: "resetPassword",
      data: e,
    });
  })) as IUserData;
}

export { list, update, signup, resetPassword };

export const config = {
  api: {
    bodyParser: false,
  },
};
