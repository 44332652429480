export const allLevels = [
  {
    id: 1,
    title: "Youth",
    value: "youth",
    isSelected: false,
    includeForm: true,
  },
  {
    id: 2,
    title: "Novice",
    value: "novice",
    isSelected: false,
    includeForm: true,
  },
  {
    id: 3,
    title: "Intermediate",
    value: "intermediate",
    isSelected: false,
    includeForm: true,
  },
  {
    id: 4,
    title: "Advanced",
    value: "advanced",
    isSelected: false,
    includeForm: true,
  },
  {
    id: 5,
    title: "Elite",
    value: "elite",
    isSelected: false,
    includeForm: true,
  },
];

export const allInterests = [
  {
    id: 1,
    title: "Strength & Conditioning",
    value: "strength",
    isSelected: false,
  },
  {
    id: 2,
    title: "Performance Nutrition",
    value: "nutrition",
    isSelected: false,
  },
  { id: 3, title: "Athletic Therapy", value: "therapy", isSelected: false },
  { id: 4, title: "Sports Psychology", value: "psychology", isSelected: false },
];

export const allCategories = [
  {
    title: "Strength & Conditioning",
    value: "strength",
    filter: 1,
  },
  {
    title: "Performance Nutrition",
    value: "nutrition",
    filter: 2,
  },
  {
    title: "Athletic Therapy",
    value: "therapy",
    filter: 3,
  },
  {
    filter: 4,
    title: "Sports Psychology",
    value: "psychology",
  },
];

export const allObjectives = [
  {
    title: "Decrease Bodyfat",
    filter: 1,
  },
  {
    title: " Develop Strength",
    filter: 2,
  },
  {
    title: " Enhance Speed",
    filter: 3,
  },
  {
    title: "Progress Fitness",
    filter: 4,
  },
  {
    title: "Increase Lean Mass",
    filter: 5,
  },
  {
    title: "Upgrade Movement",
    filter: 6,
  },
  {
    title: "Increase Agility",
    filter: 7,
  },
  {
    title: "Boost Explosiveness",
    filter: 8,
  },
  {
    title: "  Reduce Injuries",
    filter: 9,
  },
];
