import { IUserData } from "../interfaces/user/IUserData";
import { list } from "../pages/api/user";

export function buildUrlName(str: string): string {
  return str
    .toLowerCase()
    .replace(/[áäâàãå]/g, "a")
    .replace(/[čçć]/g, "c")
    .replace(/[ď]/g, "d")
    .replace(/[éěëèêẽĕȇ]/g, "e")
    .replace(/[ğ]/g, "g")
    .replace(/[íìîïı]/g, "i")
    .replace(/[ňñ]/g, "n")
    .replace(/[óöòôõøð]/g, "o")
    .replace(/[řŕ]/g, "r")
    .replace(/[šş]/g, "s")
    .replace(/[ť]/g, "t")
    .replace(/[úůüùû]/g, "u")
    .replace(/[ž]/g, "z")
    .replace(/[þ]/g, "b")
    .replace(/[Þ]/g, "B")
    .replace(/[Đ]/g, "D")
    .replace(/[đ]/g, "d")
    .replace(/[ß]/g, "B")
    .replace(/[Æ]/g, "A")
    .replace(/^\s+|\s+$/g, "")
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
}

export function getInterestLabel(interest: string) {
  let label = "";

  switch (interest) {
    case "strength":
      label = "Strength & Conditioning";
      break;
    case "nutrition":
      label = "Performance Nutrition";
      break;
    case "therapy":
      label = "Athletic Therapy";
      break;
    case "psychology":
      label = "Performance Psychology";
      break;
  }

  return label;
}

export function formatPrice(price: number | string, showCents = true): string {
  const previousPrice = String(price);
  const newPrice = previousPrice.slice(0, -2);
  const cents = previousPrice.slice(-2);
  if (!showCents) {
    return `${newPrice}`;
  }
  return `${newPrice},${cents}`;
}

export function createDateString(dateIos: Date): string {
  return new Date(dateIos).toLocaleDateString("en", {
    month: "short",
    year: "numeric",
    day: "2-digit",
  });
}

export function handleWebpError(imageUrl: string): string {
  const imageArray = imageUrl.split(".");
  imageArray[imageArray.length - 1] = "jpg";
  return imageArray.join(".");
}

export function handleSizeDataImage(
  imageUrl: string,
  size: number,
  useOriginalFormat = false
): string {
  if (imageUrl?.includes("cloudfront")) {
    const imageArray = imageUrl.split(".");
    const token = imageArray[imageArray.length - 2];
    imageArray[imageArray.length - 2] = token + `-${size}`;
    if (!useOriginalFormat) {
      imageArray[imageArray.length - 1] = "webp";
    }
    return imageArray.join(".");
  } else {
    return imageUrl;
  }
}
export function getCookie(name: string): string {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export async function getUserData(user: IUserData) {
  return await list(user);
}

export function truncate_with_ellipsis(string: string, maxLength: number) {
  if (string?.length > maxLength) {
    return string.substring(0, maxLength) + "...";
  }
  return string;
}

export function getDayName(date: Date) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days[date.getDay()];
}

export function getMonthName(date: Date) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[date.getMonth()];
}

export function getOrdinalSuffix(date: Date) {
  const day = date.getDate();
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
