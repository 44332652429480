import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { handleSizeDataImage } from "../../lib/generic";
import { IUseImageSize } from "../../interfaces/hooks/IUseImageSize";

type IUseImageSizeReturn = [
  string | null,
  (differentSize?: number) => void,
  boolean,
  Dispatch<SetStateAction<string>>
];

export default function useImageSize({
  imageUrl,
  size,
  originalFormat,
  isCover,
  isProfile,
}: IUseImageSize): IUseImageSizeReturn {
  const [resizedImage, setResizedImage] = useState(
    imageUrl
      ? handleSizeDataImage(imageUrl, size, originalFormat)
      : isCover
      ? "/images/pre-season-cycle.png"
      : null
  );
  const [usingDefaultImage, setUsingDefaultImage] = useState(
    imageUrl ? false : true
  );

  const setResizedImageOriginalFormat = (differentSize?: number) => {
    setResizedImage(
      handleSizeDataImage(imageUrl, differentSize ? differentSize : size, true)
    );
  };

  useEffect(() => {
    if (imageUrl && !originalFormat) {
      setResizedImage(handleSizeDataImage(imageUrl, size));
    } else if (imageUrl && originalFormat) {
      setResizedImage(handleSizeDataImage(imageUrl, size, true));
    }

    if (isCover && !imageUrl) {
      setResizedImage("/images/pre-season-cycle.png");
      setUsingDefaultImage(true);
    } else if (isProfile && !imageUrl) {
      // setResizedImage("/images/default-profile.svg");
      // setUsingDefaultImage(true);
    } else {
      setUsingDefaultImage(false);
    }
  }, [imageUrl, isCover, isProfile, originalFormat, size]);

  return [
    resizedImage,
    setResizedImageOriginalFormat,
    usingDefaultImage,
    setResizedImage,
  ];
}
