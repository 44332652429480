import * as Sentry from "@sentry/nextjs";
import { fetcher } from "../lib/fetcher";
import { IUserData } from "../interfaces/user/IUserData";
import { ISignUp } from "../interfaces/auth/ISignUp";
import { deleteCookie } from "../lib/authentication";
import {
  removeSearchHistoryFromLocalStorage,
  removeUserFromLocalStorage,
} from "../lib/user/localStorage";

export function logoutUser() {
  removeUserFromLocalStorage();
  removeSearchHistoryFromLocalStorage();
  deleteCookie("pod1umToken");
}

export async function resetUserPassword(params: { email: string }) {
  return (await fetcher(
    "auth/forgot",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    },
    false
  ).catch((e: Error) => {
    Sentry.captureException({
      category: "auth",
      message: "error on resetUserPassword",
      data: e,
    });
    throw e;
  })) as IUserData;
}

export async function registerUser(params: ISignUp): Promise<IUserData> {
  return (await fetcher("auth/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  }).catch((e: Error) => {
    Sentry.captureException({
      category: "auth",
      message: "error on registerUser",
      data: e,
    });
    throw e;
  })) as IUserData;
}
