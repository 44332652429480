import { Dispatch, useEffect, useState } from "react";
import { authenticate } from "../../lib/authentication";
import { NextRouter, useRouter } from "next/router";
import { IUserData } from "../../interfaces/user/IUserData";
import { IUseUser } from "../../interfaces/user/IUseUser";

export default function useUser({
  redirectTo = false,
  redirectIfFound = false,
}: IUseUser = {}): { user?: IUserData; setUser: Dispatch<IUserData> } {
  const router: NextRouter = useRouter();
  const [user, setUser] = useState<IUserData | null>(authenticate());

  useEffect(() => {
    if (
      (typeof redirectTo === "string" &&
        !redirectIfFound &&
        !user?.isLoggedIn) ||
      (redirectIfFound && !user?.token)
    ) {
      void router.push(String(redirectTo));
    }

    if (!redirectTo || !user?.token) return;
  }, [user, router, redirectIfFound, redirectTo]);

  return { user, setUser };
}
