import { DashboardFromApi } from "../interfaces/apiRequests/DashboardFromApi";
import { StripeUrlApi } from "../interfaces/apiRequests/StripeUrlApi";
import { IExerciseHistory } from "../interfaces/exercise/IExerciseHistory";
import { IUserData } from "../interfaces/user/IUserData";
import { fetcher } from "../lib/fetcher";
import * as Sentry from "@sentry/nextjs";

export async function getUser(user: IUserData): Promise<IUserData> {
  if (user.id && user.token) {
    return (await fetcher(`users/${user?.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    }).catch((e: Error) => {
      console.error(e);
      Sentry.captureException({
        category: "user",
        message: "error getting user data on user api",
        data: e,
      });
    })) as IUserData;
  } else {
    return;
  }
}

export async function updateUser(user: IUserData, data: unknown) {
  if (user.id) {
    return (await fetcher(`users/${user?.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(data),
    }).catch((e: Error) => {
      console.error(e);
      Sentry.captureException({
        category: "user",
        message: "error updating user on user api",
        data: e,
      });
      throw e;
    })) as IUserData;
  } else {
    return;
  }
}
export async function getDashboardLink(user: IUserData, period = "year") {
  return fetcher(`users/dashboard?period=${period}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  }) as Promise<DashboardFromApi>;
}

export async function getStripeUrl(user: IUserData) {
  return fetcher("payments/portal?deviceOs=web", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.token}`,
    },
  }) as Promise<StripeUrlApi>;
}

export async function getUserExerciseHistory(data: {
  month: number;
  year: number;
  token: string;
  exerciseId: number;
}) {
  return fetcher(
    `exercises/history?month=${data.month}&year=${data.year}&exerciseId=${data.exerciseId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${data.token}`,
      },
    }
  ) as Promise<IExerciseHistory[]>;
}

export default async function login(
  email: string,
  password: string
): Promise<IUserData> {
  return (await fetcher("auth/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  })
    .then((res) => {
      return res;
    })
    .catch((e: Error) => {
      Sentry.captureException({
        category: "login",
        message: "Error in login",
        data: e,
      });

      throw e;
    })) as IUserData;
}
