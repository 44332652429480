import { IUserData } from "../../interfaces/user/IUserData";

export function updateUserLocalStorage(user: IUserData, valueToUpdate: object) {
  localStorage.setItem(
    "pod1umUser",
    JSON.stringify({ ...user, ...valueToUpdate })
  );
}

export function removeUserFromLocalStorage() {
  return window.localStorage.removeItem("pod1umUser");
}

export function removeSearchHistoryFromLocalStorage() {
  return window.localStorage.removeItem("searchHistory");
}
